const cream = '#fe9';
const black = '#320';
export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Yellowtail|Roboto+Mono',
  fonts: {
    body: '"Yellowtail", cursive',
    monospace: '"Roboto Mono", Menlo, monospace'
  },
  colors: {
    text: black,
    background: cream,
    primary: black
  },
  styles: {
    root: {
      fontSize: ['1.5em', null, '4em'],
      textAlign: 'center'
    },
    pre: {
      textAlign: 'left'
    }
  }
};