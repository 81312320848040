import { CodeSurfer } from "code-surfer";
import { vsDark, nightOwl } from "@code-surfer/themes";
import { Notes } from "mdx-deck";
import 'prismjs/components/prism-csharp';
import "../../../../../../styles.css";
import devworld_intro from "../../../../../../assets/img/devworld_intro.png";
import devworld from "../../../../../../assets/img/devworld.jpg";
import sync_vs_async_vs_concurent_parallel from "../../../../../../assets/img/sync_vs_async_vs_concurent_paralell.png";
import multitask from "../../../../../../assets/img/multitask.webp";
import process_thread from "../../../../../../assets/img/process_thread.png";
import deadlock from "../../../../../../assets/img/deadlock.jpg";
import deadlock2 from "../../../../../../assets/img/deadlock2.jpg";
import actor from "../../../../../../assets/img/actor_diagram.png";
import qr_code from "../../../../../../assets/img/qr_code.svg";
import combo from "../../../../../../assets/img/sync_async_parallel_combo.png";
import philosophers from "../../../../../../assets/img/concurrent_philosophers.png";
import * as React from 'react';
export default {
  CodeSurfer,
  vsDark,
  nightOwl,
  Notes,
  devworld_intro,
  devworld,
  sync_vs_async_vs_concurent_parallel,
  multitask,
  process_thread,
  deadlock,
  deadlock2,
  actor,
  qr_code,
  combo,
  philosophers,
  React
};