'use strict';

var GetIntrinsic = require('get-intrinsic');
var callBound = require('call-bind/callBound');
var $TypeError = GetIntrinsic('%TypeError%');
var IsArray = require('./IsArray');
var $apply = GetIntrinsic('%Reflect.apply%', true) || callBound('Function.prototype.apply');

// https://262.ecma-international.org/6.0/#sec-call

module.exports = function Call(F, V) {
  var argumentsList = arguments.length > 2 ? arguments[2] : [];
  if (!IsArray(argumentsList)) {
    throw new $TypeError('Assertion failed: optional `argumentsList`, if provided, must be a List');
  }
  return $apply(F, V, argumentsList);
};