'use strict';

var hasOwn = require('hasown');
var GetIntrinsic = require('get-intrinsic');
var $TypeError = GetIntrinsic('%TypeError%');
var Type = require('./Type');
var ToBoolean = require('./ToBoolean');
var IsCallable = require('./IsCallable');

// https://262.ecma-international.org/5.1/#sec-8.10.5

module.exports = function ToPropertyDescriptor(Obj) {
  if (Type(Obj) !== 'Object') {
    throw new $TypeError('ToPropertyDescriptor requires an object');
  }
  var desc = {};
  if (hasOwn(Obj, 'enumerable')) {
    desc['[[Enumerable]]'] = ToBoolean(Obj.enumerable);
  }
  if (hasOwn(Obj, 'configurable')) {
    desc['[[Configurable]]'] = ToBoolean(Obj.configurable);
  }
  if (hasOwn(Obj, 'value')) {
    desc['[[Value]]'] = Obj.value;
  }
  if (hasOwn(Obj, 'writable')) {
    desc['[[Writable]]'] = ToBoolean(Obj.writable);
  }
  if (hasOwn(Obj, 'get')) {
    var getter = Obj.get;
    if (typeof getter !== 'undefined' && !IsCallable(getter)) {
      throw new $TypeError('getter must be a function');
    }
    desc['[[Get]]'] = getter;
  }
  if (hasOwn(Obj, 'set')) {
    var setter = Obj.set;
    if (typeof setter !== 'undefined' && !IsCallable(setter)) {
      throw new $TypeError('setter must be a function');
    }
    desc['[[Set]]'] = setter;
  }
  if ((hasOwn(desc, '[[Get]]') || hasOwn(desc, '[[Set]]')) && (hasOwn(desc, '[[Value]]') || hasOwn(desc, '[[Writable]]'))) {
    throw new $TypeError('Invalid property descriptor. Cannot both specify accessors and a value or writable attribute');
  }
  return desc;
};