const white = '#fff';
const black = '#000';
const red = '#f00';
export default {
  fonts: {
    body: '"Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  colors: {
    text: black,
    background: white,
    primary: red
  },
  styles: {
    root: {
      textAlign: 'left',
      fontSize: ['1.5em', null, '3em']
    },
    Slide: {
      display: 'block',
      padding: '2em',
      textAlign: 'left'
    }
  }
};