import React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
function currentTime() {
  if (typeof window !== "undefined") {
    return performance.now();
  } else {
    return 0;
  }
}
function useSpringInstance(target, config) {
  var ref = React.useRef(null);
  if (ref.current == null) {
    ref.current = {
      config: getConfigWithDefaults(target, config),
      state: getInitialState(target, config)
    };
  }
  return ref.current;
}
function getConfigWithDefaults(target, _ref) {
  var stiffness = _ref.stiffness,
    damping = _ref.damping,
    mass = _ref.mass,
    decimals = _ref.decimals,
    teleport = _ref.teleport;
  return {
    X: target,
    k: stiffness !== null && stiffness !== void 0 ? stiffness : 170,
    c: damping !== null && damping !== void 0 ? damping : 26,
    m: mass !== null && mass !== void 0 ? mass : 1,
    teleport: teleport !== null && teleport !== void 0 ? teleport : false,
    decimals: decimals !== null && decimals !== void 0 ? decimals : 2
  };
}
function getInitialState(target, _ref2) {
  var from = _ref2.from,
    initialSpeed = _ref2.initialSpeed;
  return {
    x0: from !== null && from !== void 0 ? from : target,
    v0: initialSpeed !== null && initialSpeed !== void 0 ? initialSpeed : 0,
    t0: currentTime(),
    raf: null
  };
}
var sqrt = Math.sqrt,
  exp = Math.exp,
  sin = Math.sin,
  cos = Math.cos;
function spring(_ref) {
  var x0 = _ref.x0,
    v0 = _ref.v0,
    t0 = _ref.t0,
    t = _ref.t,
    k = _ref.k,
    c = _ref.c,
    m = _ref.m,
    X = _ref.X;
  var dx = x0 - X;
  var dt = (t - t0) / 1000;
  var radicand = c * c - 4 * k * m;
  if (radicand > 0) {
    var rp = (-c + sqrt(radicand)) / (2 * m);
    var rn = (-c - sqrt(radicand)) / (2 * m);
    var a = (dx * rp - v0) / (rp - rn);
    var b = (v0 - dx * rn) / (rp - rn);
    return {
      x: X + a * exp(rn * dt) + b * exp(rp * dt),
      v: a * rn * exp(rn * dt) + b * rp * exp(rp * dt)
    };
  } else if (radicand < 0) {
    var r = -c / (2 * m);
    var s = sqrt(-radicand) / (2 * m);
    var _a = dx;
    var _b = (v0 - r * dx) / s;
    return {
      x: X + exp(r * dt) * (_a * cos(s * dt) + _b * sin(s * dt)),
      v: exp(r * dt) * ((_b * s + _a * r) * cos(s * dt) - (_a * s - _b * r) * sin(s * dt))
    };
  } else {
    var _r = -c / (2 * m);
    var _a2 = dx;
    var _b2 = v0 - _r * dx;
    return {
      x: X + (_a2 + _b2 * dt) * exp(_r * dt),
      v: (_b2 + _a2 * _r + _b2 * _r * dt) * exp(_r * dt)
    };
  }
}
var nextFrameQueue = [];
var nextFrameId = null;
function queueAnimationFrame(fn) {
  var length = nextFrameQueue.push(fn);
  if (length === 1) {
    nextFrameId = requestAnimationFrame(runQueue);
  }
  return [nextFrameId, length - 1];
}
function unqueueAnimationFrame(_ref) {
  var frameId = _ref[0],
    index = _ref[1];
  if (frameId === nextFrameId) {
    delete nextFrameQueue[index];
  }
}
function runQueue() {
  var now = currentTime();
  var queue = nextFrameQueue;
  nextFrameQueue = [];
  unstable_batchedUpdates(function () {
    return queue.forEach(function (task) {
      return task && task(now);
    });
  });
}
var useLayoutEffect = typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;
function useSpring(target, config) {
  if (config === void 0) {
    config = {};
  }
  var _React$useState = React.useState(),
    forceUpdate = _React$useState[1];
  var newConfig = getConfigWithDefaults(target, config);
  var _useSpringInstance = useSpringInstance(target, config),
    state = _useSpringInstance.state,
    oldConfig = _useSpringInstance.config; // TODO all springs should use the same t in the same frame

  var t = currentTime();
  var x0 = state.x0,
    v0 = state.v0,
    t0 = state.t0;
  var k = oldConfig.k,
    c = oldConfig.c,
    m = oldConfig.m,
    X = oldConfig.X;
  var _ref = newConfig.teleport ? {
      x: X,
      v: 0
    } : spring({
      x0: x0,
      v0: v0,
      t0: t0,
      t: t,
      k: k,
      c: c,
      m: m,
      X: X
    }),
    x = _ref.x,
    v = _ref.v;
  var moving = isMoving(x, v, t, newConfig);
  useLayoutEffect(function () {
    Object.assign(oldConfig, newConfig);
  }, [newConfig.X, newConfig.k, newConfig.c, newConfig.m, newConfig.teleport]);
  useLayoutEffect(function () {
    state.x0 = x;
    state.v0 = v;
    state.t0 = t;
  }, [x, v, t]);
  useLayoutEffect(function () {
    var loop = function loop(now) {
      var x0 = state.x0,
        v0 = state.v0,
        t0 = state.t0;
      var k = oldConfig.k,
        c = oldConfig.c,
        m = oldConfig.m,
        X = oldConfig.X,
        decimals = oldConfig.decimals;
      var _spring = spring({
          x0: x0,
          v0: v0,
          t0: t0,
          t: now,
          k: k,
          c: c,
          m: m,
          X: X
        }),
        nextX = _spring.x;
      if (roundTo(nextX, decimals) !== roundTo(x0, decimals)) {
        state.raf = null;
        forceUpdate(now);
      } else {
        state.raf = queueAnimationFrame(loop);
      }
    };
    if (moving && state.raf == null) {
      state.raf = queueAnimationFrame(loop);
    } else if (!moving && state.raf != null) {
      unqueueAnimationFrame(state.raf);
      state.raf = null;
    }
  });
  useLayoutEffect(function () {
    return function () {
      if (state.raf != null) {
        unqueueAnimationFrame(state.raf);
      }
    };
  }, []);
  return [roundTo(x, newConfig.decimals), moving];
}
function isMoving(x, v, t, _ref2) {
  var decimals = _ref2.decimals,
    X = _ref2.X,
    k = _ref2.k,
    c = _ref2.c,
    m = _ref2.m;
  if (roundTo(x, decimals) !== roundTo(X, decimals)) {
    return true;
  }
  var nextT = t + 0.016;
  var _spring2 = spring({
      x0: x,
      v0: v,
      t0: t,
      t: nextT,
      k: k,
      c: c,
      m: m,
      X: X
    }),
    nextX = _spring2.x;
  return roundTo(nextX, decimals) !== roundTo(X, decimals);
}
function roundTo(x, decimals) {
  var p = Math.pow(10, decimals);
  return Math.round(x * p) / p;
}
export { useSpring };