module.exports = [{
  plugin: require('../../gatsby-plugin-mdx/gatsby-browser.js'),
  options: {
    "plugins": [],
    "gatsbyRemarkPlugins": ["gatsby-remark-import-code"],
    "remarkPlugins": [null, null],
    "extensions": [".mdx"],
    "defaultLayouts": {},
    "lessBabel": false,
    "rehypePlugins": [],
    "mediaTypes": ["text/markdown", "text/x-markdown"],
    "root": "/vercel/path0/node_modules/mdx-deck"
  }
}, {
  plugin: require('../../gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('../../gatsby-plugin-theme-ui/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('../../gatsby-theme-mdx-deck/gatsby-browser.js'),
  options: {
    "plugins": [],
    "cli": true,
    "contentPath": "/vercel/path0/deck.mdx"
  }
}];