'use strict';

var GetIntrinsic = require('get-intrinsic');
var $gOPD = require('gopd');
var $SyntaxError = GetIntrinsic('%SyntaxError%');
var $TypeError = GetIntrinsic('%TypeError%');
var isPropertyDescriptor = require('../helpers/isPropertyDescriptor');
var IsAccessorDescriptor = require('./IsAccessorDescriptor');
var IsDataDescriptor = require('./IsDataDescriptor');
var IsExtensible = require('./IsExtensible');
var IsPropertyKey = require('./IsPropertyKey');
var ToPropertyDescriptor = require('./ToPropertyDescriptor');
var SameValue = require('./SameValue');
var Type = require('./Type');
var ValidateAndApplyPropertyDescriptor = require('./ValidateAndApplyPropertyDescriptor');

// https://262.ecma-international.org/6.0/#sec-ordinarydefineownproperty

module.exports = function OrdinaryDefineOwnProperty(O, P, Desc) {
  if (Type(O) !== 'Object') {
    throw new $TypeError('Assertion failed: O must be an Object');
  }
  if (!IsPropertyKey(P)) {
    throw new $TypeError('Assertion failed: P must be a Property Key');
  }
  if (!isPropertyDescriptor({
    Type: Type,
    IsDataDescriptor: IsDataDescriptor,
    IsAccessorDescriptor: IsAccessorDescriptor
  }, Desc)) {
    throw new $TypeError('Assertion failed: Desc must be a Property Descriptor');
  }
  if (!$gOPD) {
    // ES3/IE 8 fallback
    if (IsAccessorDescriptor(Desc)) {
      throw new $SyntaxError('This environment does not support accessor property descriptors.');
    }
    var creatingNormalDataProperty = !(P in O) && Desc['[[Writable]]'] && Desc['[[Enumerable]]'] && Desc['[[Configurable]]'] && '[[Value]]' in Desc;
    var settingExistingDataProperty = P in O && (!('[[Configurable]]' in Desc) || Desc['[[Configurable]]']) && (!('[[Enumerable]]' in Desc) || Desc['[[Enumerable]]']) && (!('[[Writable]]' in Desc) || Desc['[[Writable]]']) && '[[Value]]' in Desc;
    if (creatingNormalDataProperty || settingExistingDataProperty) {
      O[P] = Desc['[[Value]]']; // eslint-disable-line no-param-reassign
      return SameValue(O[P], Desc['[[Value]]']);
    }
    throw new $SyntaxError('This environment does not support defining non-writable, non-enumerable, or non-configurable properties');
  }
  var desc = $gOPD(O, P);
  var current = desc && ToPropertyDescriptor(desc);
  var extensible = IsExtensible(O);
  return ValidateAndApplyPropertyDescriptor(O, P, extensible, Desc, current);
};