'use strict';

var assertRecord = require('../helpers/assertRecord');
var IsAccessorDescriptor = require('./IsAccessorDescriptor');
var IsDataDescriptor = require('./IsDataDescriptor');
var Type = require('./Type');

// https://262.ecma-international.org/6.0/#sec-isgenericdescriptor

module.exports = function IsGenericDescriptor(Desc) {
  if (typeof Desc === 'undefined') {
    return false;
  }
  assertRecord(Type, 'Property Descriptor', 'Desc', Desc);
  if (!IsAccessorDescriptor(Desc) && !IsDataDescriptor(Desc)) {
    return true;
  }
  return false;
};