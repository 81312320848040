'use strict';

var GetIntrinsic = require('get-intrinsic');
var hasOwn = require('hasown');
var $TypeError = GetIntrinsic('%TypeError%');
module.exports = function IsPropertyDescriptor(ES, Desc) {
  if (ES.Type(Desc) !== 'Object') {
    return false;
  }
  var allowed = {
    '[[Configurable]]': true,
    '[[Enumerable]]': true,
    '[[Get]]': true,
    '[[Set]]': true,
    '[[Value]]': true,
    '[[Writable]]': true
  };
  for (var key in Desc) {
    // eslint-disable-line no-restricted-syntax
    if (hasOwn(Desc, key) && !allowed[key]) {
      return false;
    }
  }
  if (ES.IsDataDescriptor(Desc) && ES.IsAccessorDescriptor(Desc)) {
    throw new $TypeError('Property Descriptors may not be both accessor and data descriptors');
  }
  return true;
};